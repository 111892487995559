<template>
    <base-layout :public="true" :fill-height="true">
        <v-col class="align-center">
            <v-row justify="center" class="pb-10"><img src="@/assets/TK_logo_dark.svg" class="logo"/></v-row>
            <template v-if="!isValidUserToken">
                <v-row>
                    <template v-if="isLoadingVerifyUserToken">
                        <v-row justify="center" class="pt-10">
                            <v-progress-circular :size="120" :width="8" color="regalBlue" indeterminate></v-progress-circular>
                        </v-row>
                    </template>
                    <template v-else>
                        <v-card class="mx-auto px-6" max-width="600">
                            <v-container fluid pa-10>
                                <v-row justify="center" class="pb-2">
                                    <div class="title regalBlue--text">Invalid link for reset password!</div>
                                </v-row>
                                <v-row justify="center" class="pt-4 pb-8">
                                    <v-col cols="8" class="text-center">
                                        <div class="sub-title martinique--text">
                                            Please use reset password once again.
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="pb-5">
                                    <v-col cols="8">
                                        <v-btn @click="$router.push({ name: 'login' })" color="mangoTango" block dark>
                                            <span class="log-in-text">Log in</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </template>
                </v-row>
            </template>
            <template v-else-if="!isPasswordRecovered">
                <v-row>
                    <v-card class="mx-auto px-6" max-width="500">
                        <v-container fluid pa-10>
                            <v-row justify="center" class="pb-2">
                                <div class="title regalBlue--text text-center">Please enter your new password</div>
                            </v-row>
                            <v-row justify="center" class="pt-5">
                                <div class="sub-title martinique--text text-center">
                                    Please make sure your password has at least 8 characters
                                </div>
                            </v-row>
                            <v-card-text class="pt-5 b-0">
                                <span class="password-text casper--text">PASSWORD</span>
                                <v-text-field
                                    v-model="password"
                                    :disabled="isLoadingResetPassword"
                                    :type="showPassword ? 'text' : 'password'"
                                    :class="[isPasswordHasError ? 'recovery-password-text-field-error' : 'recovery-password-text-field']"
                                    class="pt-0 mt-0 pb-5"
                                    background-color="whiteLilac"
                                    hide-details
                                >
                                    <template v-slot:append>
                                        <span @click="showPassword = !showPassword" class="cursor-pointer">
                                            <img v-if="!showPassword" src="@/assets/icons/eye.svg" />
                                            <img v-else src="@/assets/icons/eye_off.svg" />
                                        </span>
                                    </template>
                                </v-text-field>
                                <span class="confirm-password-text casper--text">CONFIRM PASSWORD</span>
                                <v-text-field
                                    v-model="confirmPassword"
                                    :disabled="isLoadingResetPassword"
                                    :type="showConfirmPassword ? 'text' : 'password'"
                                    :class="[isConfirmPasswordHasError ? 'recovery-password-text-field-error' : 'recovery-password-text-field']"
                                    class="pt-0 mt-0 pb-8"
                                    background-color="whiteLilac"
                                    hide-details
                                >
                                    <template v-slot:append>
                                        <span @click="showConfirmPassword = !showConfirmPassword" class="cursor-pointer">
                                            <img v-if="!showConfirmPassword" src="@/assets/icons/eye.svg" />
                                            <img v-else src="@/assets/icons/eye_off.svg" />
                                        </span>
                                    </template>
                                </v-text-field>
                            </v-card-text>
                            <v-row justify="center" class="pb-5">
                                <v-col cols="8">
                                    <v-btn @click="onResetPassword()" :loading="isLoadingResetPassword" color="mangoTango" block dark>
                                        <span class="set-new-password-text">Set new password</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-row>
                <v-row justify="center" class="pt-5">
                    <v-btn @click="$router.push({ name: 'login' })" :disabled="isLoadingResetPassword" color="paleSky" text>
                        <span class="back-to-login-text">Back to Log In</span>
                    </v-btn>
                </v-row>
            </template>
            <template v-else>
                <v-row>
                    <v-card class="mx-auto px-6" max-width="600">
                        <v-container fluid pa-10>
                            <v-row justify="center" class="pb-2">
                                <div class="title regalBlue--text">Password has been recovered!</div>
                            </v-row>
                            <v-row justify="center" class="pt-4 pb-8">
                                <v-col cols="8" class="text-center">
                                    <div class="sub-title martinique--text">
                                        You can enter the site using the new password.
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="pb-5">
                                <v-col cols="8">
                                    <v-btn @click="$router.push({ name: 'login' })" color="mangoTango" block dark>
                                        <span class="log-in-text">Log in</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-row>
            </template>
        </v-col>
    </base-layout>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

export default {
    name: 'RecoveryPassword',
    data() {
        return {
            email: '',
            token: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false,
            isLoadingResetPassword: false,
            isLoadingVerifyUserToken: false,
            isValidUserToken: false,
            isPasswordRecovered: false,

            isPasswordHasError: false,
            isConfirmPasswordHasError: false,
        };
    },
    created() {
        window.addEventListener('keydown', this.onPressKey, false);
        this.email = this.$route.query.email;
        this.token = this.$route.query.token;
    },
    mounted() {
        this.onVerifyUserToken();
    },
    destroyed() {
        window.removeEventListener('keydown', this.onPressKey, false);
    },
    computed: {
        backgroundColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
        },
    },
    methods: {
        ...mapActions('restorePassword', {
            verifyUserToken: 'verifyUserToken',
            resetPassword: 'resetPassword',
        }),
        onPressKey(e) {
            e = e || window.event;
            if (e.keyCode === 13) {
                if (!this.isValidUserToken && !this.isLoadingVerifyUserToken) {
                    this.$router.push({ name: 'login' });
                } else {
                    if (!this.isLoadingVerifyUserToken && !this.isLoadingResetPassword) {
                        this.onResetPassword();
                    }
                }
            }
        },
        async onVerifyUserToken() {
            this.isLoadingVerifyUserToken = true;
            await this.verifyUserToken({
                email: this.email,
                token: this.token,
            }).then((res) => {
                if (res) {
                    this.isValidUserToken = true;
                } else {
                    this.isLoadingVerifyUserToken = false;
                }
            });
        },
        async onResetPassword() {
            this.isPasswordHasError = false;
            this.isConfirmPasswordHasError = false;
            if (
                this.email &&
                this.token &&
                this.password &&
                this.confirmPassword &&
                this.password.length >= 8 &&
                this.confirmPassword.length >= 8 &&
                this.password === this.confirmPassword
            ) {
                this.isLoadingResetPassword = true;
                await this.resetPassword({
                    email: this.email,
                    password: this.password,
                    token: this.token,
                }).then((res) => {
                    if (res) {
                        this.isPasswordRecovered = true;
                    } else {
                        this.isPasswordHasError = true;
                        this.isConfirmPasswordHasError = true;
                        this.isLoadingResetPassword = false;
                    }
                });
            } else {
                if (!this.email) {
                    Vue.toasted.error('No email in the link');
                }
                if (!this.token) {
                    Vue.toasted.error('No token in the link');
                }
                if (!this.password) {
                    this.isPasswordHasError = true;
                    Vue.toasted.error('Password field must not be empty');
                }
                if (!this.confirmPassword) {
                    this.isConfirmPasswordHasError = true;
                    Vue.toasted.error('Confirm password field must not be empty');
                }
                if (this.password.length < 8) {
                    this.isPasswordHasError = true;
                    Vue.toasted.error('Password must be more than 8 characters');
                }
                if (this.confirmPassword.length < 8) {
                    this.isConfirmPasswordHasError = true;
                    Vue.toasted.error('Confirm password must be more than 8 characters');
                }
                if (!this.password !== this.confirmPassword) {
                    this.isPasswordHasError = true;
                    this.isConfirmPasswordHasError = true;
                    Vue.toasted.error('Password and Confirm Password fields must match');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    width: 120px;
}

.title {
    font-size: 32px !important;
    line-height: 38px;
    letter-spacing: -0.114286px !important;
}

.sub-title {
    font-size: 15px;
    line-height: 22px;
}

.password-text,
.confirm-password-text {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1.21333px;
}

.set-new-password-text {
    font-size: 15px;
    line-height: 21px;
}

.back-to-login-text {
    font-size: 14px;
    line-height: 17px;
}
</style>
